import React from 'react'
import { Content as EntryContent } from './Entries'
import * as styles from './styles.module.scss'

/**
 *
 */
const Entries = () => (
  <div className={styles.Content}>
    <EntryContent />
  </div>
)

/**
 *
 */
const Container = ({ children }) => <div>{children}</div>

/**
 *
 */
export const Content = () => (
  <Container>
    <Entries />
  </Container>
)
