import React from 'react'
import { Content as PostsContent } from './CompanyProductPosts'
import * as CompanyReference from 'modules/company/components/Reference'
import * as styles from './styles.module.scss'

/**
 *
 */
const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 *
 */
const CompanyPostContent = () => <CompanyReference.Properties.Content />

/**
 *
 */
const CompanyProductPosts = () => (
  <div>
    <PostsContent />
  </div>
)

/**
 *
 */
export const Content = () => (
  <Container>
    <CompanyPostContent />
    <CompanyProductPosts />
  </Container>
)
