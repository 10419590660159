import React from 'react'
import * as styles from './styles.module.scss'
import * as Iframe from 'components/shared/Iframe'
import * as Width from 'modules/page/width/components/Reference'

/**
 *
 */

const Entry = () => (
  <div className={styles.Entry}>
    <div className={styles.Entry_ProductListContent}>
      <Width.Initializer>
        <Iframe.AllProductList
          className={styles.Entry_ProductListContent_ProductListIframe}
        />
      </Width.Initializer>
    </div>
  </div>
)

/**
 *
 */
const Container = ({ children }) => <div>{children}</div>

/**
 *
 */
export const Content = () => (
  <Container>
    <Entry />
  </Container>
)
