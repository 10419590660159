import React from 'react'
import * as styles from './styles.module.scss'

/**
 *
 */
const Container = ({ children }) => (
  <div className={styles.Container}>{children}</div>
)

/**
 *
 */
const PageTitle = () => (
  <div className={styles.PageTitle}>
    <div className={styles.PageTitle_Title}>COMPANY INFORMATION</div>
    <div className={styles.PageTitle_Icon} />
  </div>
)

/**
 *
 */
const Discription = () => <div className={styles.Discription}>企業紹介</div>

/**
 *
 */
export const Content = () => (
  <Container>
    <PageTitle />
    <Discription />
  </Container>
)
