import React from 'react'
import { Content as PageTitle } from './Header'
import { Content as BodyContent } from './Body'
import { Content as Links } from 'components/shared/Common_button'
import * as styles from './styles.module.scss'

/**
 *
 */
const Container = ({ children }) => <div>{children}</div>

/**
 *
 */
const Header = () => (
  <div>
    <PageTitle />
  </div>
)

/**
 *
 */
const Body = () => (
  <div>
    <BodyContent />
  </div>
)

/**
 *
 */
const Footer = () => (
  <div className={styles.Footer}>
    <Links />
  </div>
)

/**
 *
 */
export const Content = () => (
  <Container>
    <Header />
    <Body />
    <Footer />
  </Container>
)
