import React from 'react'
import { graphql } from 'gatsby'
import { Content } from 'partials/CompanyPage'
import * as CompanyReference from 'modules/company/components/Reference'
import { Layout as PostPageLayout } from 'layouts/PostPageLayout'
import * as Category from '../modules/category/components/Reference'
import * as Product from '../modules/product/components/Reference'

/**
 *
 */
const CompanyTemplate = ({ data, pageContext }) => {
  const company = data.company
  const companyNode = pageContext
  const wordpressID = data.product
  const { styleModel } = pageContext
  const { meta } = pageContext
  const length = data.postLength.nodes.length
  return (
    <PostPageLayout styleModel={styleModel} meta={meta}>
      <Product.Provider product={wordpressID}>
        <CompanyReference.Provider company={company}>
          <Category.Provider category={{ companyNode, length }}>
            <Content />
          </Category.Provider>
        </CompanyReference.Provider>
      </Product.Provider>
    </PostPageLayout>
  )
}

/**
 *
 */
export const query = graphql`
  query CompanyTemplateQuery(
    $slug: String!
    $companySlug: String!
    $postStatus: String!
  ) {
    company: wordpressPost(
      categories: {
        elemMatch: { slug: { eq: "companies", in: [$companySlug] } }
      }
    ) {
      title
      content
      categories {
        slug
      }
    }
    categorySlug: wordpressPost(
      slug: { eq: $slug }
      status: { eq: "publish" }
    ) {
      categories {
        slug
      }
    }
    postLength: allWordpressPost(
      filter: {
        categories: {
          elemMatch: { slug: { eq: "products", in: [$companySlug] } }
        }
        status: { glob: $postStatus }
      }
    ) {
      nodes {
        title
      }
    }
  }
`

/**
 *
 */
export default CompanyTemplate
